import {
  call, put, takeLatest, select,
} from 'redux-saga/effects';

import PaymentMethodsActions, { PaymentMethodsTypes } from 'reducers/paymentMethods';
import CreateCreditCardActions, { CreateCreditCardTypes } from 'reducers/createCreditCard';
import CheckoutActions from 'reducers/checkout';
import GuestActions from 'reducers/guest';

import CreditCardsApi from 'apis/supremeGolfApi/CreditCardsApi';
import GuestApi from 'apis/supremeGolfApi/GuestApi';

export function* requestCreditCardsHandler() {
  try {
    const creditCards = yield call(CreditCardsApi.getCreditCards);
    yield put(PaymentMethodsActions.getCreditCardsDone(creditCards));
  } catch (error) {
    yield put(PaymentMethodsActions.getCreditCardsError(error.message));
  }
}

export function* requestCreateCreditCardHandler({
  token,
  gRecaptchaResponseData,
  callback,
}) {
  try {
    const { loggedIn } = yield select((state) => state.profile);
    let result;
    if (loggedIn) {
      result = yield call(CreditCardsApi.postCreditCard, token, gRecaptchaResponseData);
    } else {
      result = yield call(GuestApi.addGuestCreditCard, { token, gRecaptchaResponseData });
      yield put(CheckoutActions.setCreditCard(result.creditCard));

      yield put(GuestActions.trackGuestCheckoutEvent('guest_add_payment_method_success', 'Guest checkout'));
    }

    yield put(CreateCreditCardActions.createCreditCardDone());
    if (callback) callback(null, result.creditCard);
  } catch (error) {
    const { response: { data: { error: responseError } = {} } = {} } = error;
    const errorMessage = responseError || error.message;
    yield put(CreateCreditCardActions.createCreditCardError(errorMessage, null));

    const { loggedIn } = yield select((state) => state.profile);
    if (!loggedIn) {
      yield put(GuestActions.trackGuestCheckoutEvent('guest_add_payment_method_failed', 'Guest checkout'));
    }

    if (callback) callback();
  }
}

export function* requestDeleteCreditCardHandler({
  id, callback,
}) {
  try {
    const creditCardSelected = yield select((state) => state.checkout.creditCard);

    yield call(CreditCardsApi.deleteCreditCard, id);
    yield put(PaymentMethodsActions.deleteCreditCardDone(id));

    if (creditCardSelected && creditCardSelected.id === id) {
      yield put(CheckoutActions.setCreditCard(null));
    }
    if (callback) callback();
  } catch (error) {
    const errorMessage = error.response.data.error || error.message;

    yield put(PaymentMethodsActions.deleteCreditCardError({
      cardId: id,
      message: errorMessage,
    }));
  }
}

function* requestCreditCardsWatcher() {
  yield takeLatest(PaymentMethodsTypes.GET_CREDIT_CARDS, requestCreditCardsHandler);
}

function* requestCreateCreditCardWatcher() {
  yield takeLatest(CreateCreditCardTypes.CREATE_CREDIT_CARD, requestCreateCreditCardHandler);
}

function* requestDeleteCreditCardWatcher() {
  yield takeLatest(PaymentMethodsTypes.DELETE_CREDIT_CARD, requestDeleteCreditCardHandler);
}

export default [
  requestCreditCardsWatcher,
  requestCreateCreditCardWatcher,
  requestDeleteCreditCardWatcher,
];
