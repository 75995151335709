const ott = (n) => {
  const range = {};
  for (let i = 0; i <= n; i += 0.5) {
    range[i] = `${i * 0.25}rem`;
  }
  return range;
};

module.exports = {
  theme: {
    screens: {
      xs: '400px',
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1440px',
    },
    extend: {
      maxWidth: {
        50: '12.5rem',
        68: '17rem',
        74: '18.5rem',
        104: '26rem',
        120: '30rem',
        128: '32rem',
        144: '36rem',
        196: '49rem',
        217: '54.25rem',
        250: '58rem',
        314: '78.5rem',
        300: '75rem',
        320: '80rem',
      },
      minWidth: {
        50: '12.5rem',
        68: '17rem',
      },
      transitionProperty: {
        'max-height': 'max-height',
      },
      boxShadow: {
        'club-button': '0 2px 10px rgba(24, 69, 56, 0.3)',
        'cookies-modal': '0 6px 14px 0 rgba(0, 0, 0, 0.3)',
        slider: '0 0 0 5px rgba(251, 76, 6, 0.2)',
        try: '0 0.25rem 1rem 0 rgba(0, 0, 0, 0.1)',
        usual: '0 4px 16px 0 rgba(0, 0, 0, 0.1)',
      },
      fontFamily: {
        montserrat: ['Montserrat', 'montserrat'],
        serif: ['SourceSerifPro', 'serif'],
        grotesk: ['NiveauGrotesk'],
        roboto: ['Roboto'],
        gotham: ['Gotham', 'gotham', 'Poppins'],
        tungsten: ['Tungsten', 'tungsten', 'Poppins', 'poppins'],
        korolav: ['Korolav', 'korolav', 'Poppins', 'poppins'],
        poppins: ['poppins', 'Poppins', 'Poppins', 'poppins'],
        rajdhani: ['Rajdhani', 'rajdhani', 'Poppins', 'poppins'],
      },
      borderWidth: {
        tiny: '.03125rem',
      },
      borderRadius: { ...ott(10) },
      height: {
        15: '3.75rem',
        18: '4.5rem',
        42: '10.5rem',
        50: '12.5rem',
        54: '14.375rem',
        160: '40rem',
        172: '43rem',
        '90vh': '90vh',
      },
      lineHeight: {
        3.5: '0.875rem',
        11: '2.75rem',
      },
      width: {
        '5-5': '1.375rem',
        13: '3.25rem',
        18: '4.5rem',
        27: '6.75rem',
        50: '12.5rem',
        58: '14.5rem',
        68: '17rem',
        88: '22rem',
        100: '25rem',
        104: '26rem',
        120: '30rem',
        '10p': '10%',
        '15p': '15p',
      },
      letterSpacing: {
        0: '0',
        0.11: '0.11px',
        0.16: '0.16px',
        0.2: '0.2px',
        0.25: '0.25px',
        0.3: '0.3px',
        0.4: '0.4px',
        0.6: '0.6px',
        0.8: '0.8px',
        1: '1px',
        2: '2px',
        3: '3px',
        5: '5px',
      },
      maxHeight: {
        34: '8.5rem',
        50: '13rem',
        100: '25rem',
      },
      margin: {
        4.5: '1.125rem',
        18: '4.5rem',
        26: '6.5rem',
        88: '22rem',
      },
      padding: {
        tiny: '0.1875rem',
        sm: '0.625rem',
        usual: '0.875rem',
        '0-5': '0.125rem',
        '1-2': '0.375rem',
        '4-5': '1.125rem',
        13: '3.25rem',
        15: '3.75rem',
        18: '4.5rem',
        22: '5.5rem',
        26: '6.5rem',
        34: '8.5rem',
      },
      inset: {
        18: '4.5rem',
      },
      zIndex: {
        100: '100',
      },
      rotate: {
        270: '270deg',
      },
    },
    colors: {
      'algae-green': '#24c46e',
      'blue-facebook': '#3b5998',
      'blue-google': '#4285f4',
      'blue-grey': '#818f9d',
      'blue-purple': '#532ba8',
      'cloudy-blue': '#c0c7ce',
      'club-green-alt': '#249f5b',
      'club-green': '#2dbd6e',
      coral: '#ff4545',
      dark: '#1d1d22',
      'dark-two': '#1f1d37',
      'discount-green': '#0e7b5a',
      'special-red': '#da3333',
      'dark-red': '#fd4d2f',
      'dark-grey-blue': '#44586b',
      'dark-grey-three': '#1e1e20',
      'dark-grey': '#1c1c1e',
      'dodger-blue': '#1b49bd',
      'electric-blue': '#004aff',
      grey: '#9e9e9e',
      'grey-2': '#666666',
      'grey-3': '#606064',
      'grey-4': '#e5e5e5',
      'grey-medium': '#7f7f7f',
      'mild-grey': '#4a4a4e',
      'ice-blue': '#eef1f3',
      'light-grey-blue-five': '#c6c6c8',
      'light-grey-blue-two': '#adafb8',
      'light-grey-blue': '#adb9c5',
      'light-grey-four': '#cccccc',
      'light-grey-three': '#c4cbd5',
      'light-grey-two': '#cccfdb',
      'light-grey': '#ced6df',
      'light-grey-2': '#f2f3f7',
      'light-grey-3': '#cccccc',
      'light-periwinkle-three': '#dddfea',
      'light-periwinkle': '#bfd2ff',
      'lighter-red': '#fff8f8',
      'membership-blue': '#4a30da',
      navy: '#041f3b',
      'orangey-red': '#fd4e30',
      'pale-grey': '#ecedf4',
      'periwinkle-three': '#e4e7ea',
      red: '#d31f1f',
      salmon: '#ff7474',
      secondary: '#041f3b',
      'secondary-hover': '#d6dce2',
      shamrock: '#00b252',
      silver: '#bfbfbf',
      slate: '#43576c',
      'slate-grey': '#56565a',
      'very-light-orange': '#feedea',
      'white-one': '#f5f7f8',
      'white-two': '#f5f5f5',
      transparent: 'transparent',
      black: '#000000',
      white: '#ffffff',
      current: 'currentColor',
      inherit: 'inherit',
    },
    fontSize: { ...ott(30), 3.75: '0.9375rem' },
  },
};
